<template>
  <div class="register">
  
       <el-card class="box-card">
  <div slot="header" class="clearfix">
    <span>企业注册信息</span>
    <!-- <el-button style="float: right; padding: 3px 0" type="text" @click="tologin">返回登录</el-button> -->
  </div>
  <el-form ref="form" :disabled="true" :inline="true" :model="form" label-width="140px" class="form">
            <el-form-item label="单位名称" prop="j_dwmc">
            <el-input placeholder="请输入内容" v-model="form.j_dwmc" class="input-with-select">
            <el-select v-model="form.j_nature" slot="prepend" @change="isgeti" class="danwei" placeholder="单位性质">
                 <el-option
      v-for="item in nature"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
            <!-- <el-option label="企业" value=1></el-option>
            <el-option label="合作社" value=2></el-option>
            <el-option label="种植户" value=3></el-option> -->
            </el-select>
            </el-input>
            </el-form-item>
            <el-form-item label="单位类型" >
                  <el-checkbox-group v-model="form.qylx">
                <el-checkbox-button v-for="qy in qyclass" :label="qy.id" :key="qy.id">{{qy.name}}</el-checkbox-button>
                </el-checkbox-group>  
            </el-form-item>
            <el-form-item label="社会统一信用代码">
    <el-input v-model="form.j_licence" :disabled="licence"></el-input>
  </el-form-item>
  <el-form-item label="组织机构代码">
    <el-input v-model="form.j_organization" :disabled="organization"></el-input>
  </el-form-item>
  <el-form-item label="法人姓名">
    <el-input v-model="form.j_legal" :disabled="legal"></el-input>
  </el-form-item>
  <el-form-item label="所在区域" prop="j_address">
            <el-input placeholder="详细地址" v-model="form.j_area" class="input-with-select">
            
            </el-input>
            </el-form-item>
  <!-- <el-form-item label="所在区域">
    <el-cascader
      size="large"
      :options="options"
      v-model="selectedOptions"
      @change="handleChange">
    </el-cascader>
  </el-form-item> -->
  <el-form-item label="负责人" prop="j_name" >
    <el-input v-model="form.j_name"></el-input>
  </el-form-item>
  <el-form-item label="身份证号" prop="j_idnum">
    <el-input v-model="form.j_idnum"></el-input>
  </el-form-item>
  <el-form-item label="手机号码" prop="j_mobile">
    <el-input v-model="form.j_mobile"></el-input>
  </el-form-item>
  <el-form-item label="电子邮箱" prop="j_email">
    <el-input v-model="form.j_email"></el-input>
  </el-form-item>
   <el-divider></el-divider>
    <el-form-item label="注册资本">
    <el-input v-model="form.j_zczb">
        <template slot="append">万元</template>
    </el-input>
  </el-form-item>
  <el-form-item label="固定资本">
    <el-input v-model="form.j_gdzb">
        <template slot="append">万元</template>
    </el-input>
  </el-form-item>
  <el-form-item label="从业人数">
    <el-input v-model="form.j_cyrs">
        <template slot="append">人</template>
    </el-input>
  </el-form-item>
  <el-form-item label="经营范围">
    <el-input v-model="form.j_jyfw"></el-input>
  </el-form-item>
  <el-form-item label="联系地址">
    <el-input v-model="form.j_dwdz"></el-input>
  </el-form-item>
  <el-form-item label="邮政编码">
    <el-input v-model="form.j_yzbm"></el-input>
  </el-form-item>
  <el-form-item label="单位传真">
    <el-input v-model="form.j_dwcz"></el-input>
  </el-form-item>
  <el-form-item label="单位网站">
    <el-input v-model="form.j_dwmz"></el-input>
  </el-form-item>
    <el-form-item label="单位简介" class="jianjie">
    <el-input type="textarea" class="jian" v-model="form.j_profile"></el-input>
  </el-form-item>
        </el-form>
        <!-- <el-form-item> -->
            <div style="text-align:center;">
                <el-button type="primary" @click="onSubmit">修改信息</el-button>
                <!-- <el-button @click="tologin">取消</el-button> -->
            </div>
                
            <!-- </el-form-item> -->
</el-card>

     
  </div>
</template>

<script>
import { regionDataPlus,CodeToText  } from 'element-china-area-data'

export default {
    data(){
        return{
            nature:[
                {
                    label:"企业",
                    value:1

                },
                {
                    label:"合作社",
                    value:2

                },
                {
                    label:"种植户",
                    value:3

                },
            ],
           
            options: regionDataPlus,
            selectedOptions: [],
            select:'',
            input3:'',
            licence:false,
            organization:false,
            legal:false,
            city:'',
            qyclass:[
               
            ],
           qyclassSel:[
                {
                    name:"生产",
                    id:"1"
                },
                 {
                    name:"加工",
                    id:"2"
                },
                 {
                    name:"销售",
                    id:"3"
                },
           ],
            form:{
                qylx:[],
                j_nature:'',
                j_type:'',
                j_dwmc:'',
                j_licence:'',
                j_organization:'',
                j_legal:'',
                j_name:'',
                j_idnum:'',
                j_mobile:'',
                j_email:'',
                j_zczb:'',
                j_gdzb:'',
                j_cyrs:'',
                j_jyfw:'',
                j_dwdz:'',
                j_yzbm:'',
                j_dwcz:'',
                j_dwmz:'',
                j_profile:'',
                j_area:'',
                j_address:''



            }

        }
    },
    created(){
        this.getUserInfo()
    },
    methods:{
        handleChange(e){
            console.log(CodeToText[e[0]]+CodeToText[e[1]]+CodeToText[e[2]])
            // CodeToText[e[0]]
            this.city=CodeToText[e[0]]+CodeToText[e[1]]+CodeToText[e[2]]
        },
        async getUserInfo(){
                const {data:res} =await this.$http.post("getUserInfo")
                this.form=res.data
               this.form.qylx=res.data.j_type.split(",")
               this.form.qylx.forEach(item => {
                   this.qyclassSel.forEach(item1 => {
                       if(item1["id"]==item){
                           this.qyclass.push(item1)
                       }
                       
                   });
                   
               });
                // this.qyclass
               
                console.log(this.form.qylx)
        },
         onSubmit(){
          this.$router.push("editqyinfo")
            
        },
        tologin(){
            this.$router.push('/login')
        },
        isgeti(e){
            if(e==3){
                this.licence=true
                this.organization=true
                this.legal=true
            }else{
                this.licence=false
                this.organization=false
                this.legal=false
            }
        }
    }

}
</script>

<style lang="less" >
.is-disabled .el-input__inner {
    color: #606266!important;
    background-color: #fff!important;
}
.el-checkbox-button.is-disabled.is-checked .el-checkbox-button__inner {
    color: #FFFFFF;
    background-color: #1890ff;
    border-color: #1890ff;
    -webkit-box-shadow: -1px 0 0 0 #74bcff;
    box-shadow: -1px 0 0 0 #74bcff;
}


// .form{
//     background-color: #fff;
//     width: 1200px;
    
// }
.danwei{
    width: 200px;
}
.box-card {
    width: 99%;
    margin: 0 auto;
  }

.img {
        height: 114px;
    }

.el-select .el-input {
    width: 200px;
  }
  .el-form-item{
      width: 560px;
      margin-bottom: 15px;
  }
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
//  .register {
//         height: 100%;
//         background: url() bottom center no-repeat;
//         background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
//         background-image: linear-gradient(0deg,#30cfd0 0,#330867);
//         // background-image: ;
//         // url("../assets/img/zz.png"),
//         background-size: 100% 100%;
//     }
    .jianjie{
        width: 800px;
    }
    .jian{
        width: 600px;
    }
    
</style>